// Generated by Framer (838580a)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Hero } from "https://framerusercontent.com/modules/hKjtTuWGYB451ckw6eTN/bbq95ZQZ142lE2fXscHd/Hero.js";
import * as localizedValues from "./DW9Ae04VT-0.js";
const HeroFonts = getFonts(Hero);

const cycleOrder = ["CgQO_Bj53"];

const serializationHash = "framer-E31e8"

const variantClassNames = {CgQO_Bj53: "framer-v-16sqf7u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {aY51oYwly: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HVJi1YMqVaPCAG0i1k, VBO6NQBKEaPCAG0i1k, v2WcM4ddaaPCAG0i1k, vEoc_otagaPCAG0i1k, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "CgQO_Bj53", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16sqf7u", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CgQO_Bj53"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-133n980-container"} layoutDependency={layoutDependency} layoutId={"Dc82W4D2N-container"}><Hero color={"var(--token-c5f1da1f-e558-4012-bcfa-0c426f51b0a3, rgb(43, 159, 83))"} height={"100%"} iconSearch={v2WcM4ddaaPCAG0i1k} iconSelection={VBO6NQBKEaPCAG0i1k} id={"Dc82W4D2N"} layoutId={"Dc82W4D2N"} mirrored={false} selectByList={HVJi1YMqVaPCAG0i1k} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "1.3em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-6ba06e6b-fdc9-4ffb-9d96-dab5421e4e3b, rgb(255, 255, 255)))"}}>Conta Digital para Organizações</motion.p></React.Fragment>} className={"framer-to4j1r"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"YnA2qgLQM"} style={{"--extracted-r6o4lv": "var(--token-6ba06e6b-fdc9-4ffb-9d96-dab5421e4e3b, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={vEoc_otagaPCAG0i1k} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-E31e8.framer-1lolfvk, .framer-E31e8 .framer-1lolfvk { display: block; }", ".framer-E31e8.framer-16sqf7u { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px; position: relative; width: 267px; }", ".framer-E31e8 .framer-133n980-container { flex: none; height: 21px; position: relative; width: 21px; }", ".framer-E31e8 .framer-to4j1r { -webkit-user-select: none; flex: 1 0 0px; height: auto; position: relative; user-select: none; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-E31e8.framer-16sqf7u { gap: 0px; } .framer-E31e8.framer-16sqf7u > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-E31e8.framer-16sqf7u > :first-child { margin-left: 0px; } .framer-E31e8.framer-16sqf7u > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 267
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDW9Ae04VT: React.ComponentType<Props> = withCSS(Component, css, "framer-E31e8") as typeof Component;
export default FramerDW9Ae04VT;

FramerDW9Ae04VT.displayName = "testee";

FramerDW9Ae04VT.defaultProps = {height: 41, width: 267};

addFonts(FramerDW9Ae04VT, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2", weight: "500"}]}, ...HeroFonts], {supportsExplicitInterCodegen: true})